<template>
  <div class="cut-wrap">
    <NavBar />
    <ul class="shop-content">
      <li v-for="(item,index) in shoplist" :key="index" @click="chooseShopItem(item)">
        <h4>{{item.shop_name}}</h4>
        <van-icon name="checked" v-show="userStatus.curs_node_id_2.substring(0,5)==item.node_id.substring(0,5)" />
      </li>
    </ul>
  </div>
</template>
<script>
import table from '@/api/table';
import NavBar from '@/components/NavBar';
import { mapState,mapMutations } from 'vuex';
export default {
  name: 'cut',
  components: { NavBar },
  data () {
    return {
      shoplist:[]
    }
  },
  computed:{...mapState(['userStatus'])},
  mounted () {
    this.getShopList()
  },
  methods: {
    ...mapMutations(['updateCursNodeId2']),
    getShopList(){
      table.getShopList().then(response=>{
        this.shoplist=response.data.list
      })
    },
    // 切换门店
    chooseShopItem(item){
        table.changeNodeId({node_id:item.node_id}).then(response=>{
            if(response.data.result){
                this.updateCursNodeId2(item.node_id)
                localStorage.setItem('shopNameTitle',item.shop_name)
                this.$router.go(-1)
            }
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.cut-wrap {
  .shop-content{
    padding: 30px 22px;
    li{
      background: #fff;
      margin-bottom: 22px;
      padding: 30px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h4{
        font-size: 30px;
        font-weight: 500;
        color: #333333;
      }
      .van-icon-checked{
          font-size:40px;
          color:#4C1EFF;
      }
    }
  }
}
</style>
